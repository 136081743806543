import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";

export default {
    name: 'advancedSearchModal',
    props: {
        resultsamount: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        phone: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        time: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        propertyid: {
            type: String,
            default: ''
        },

    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox

    },
    watch: {
        propertyLocation(newPropertyLocation, old) {
            let vm = this;
            this.districts = {};
            this.cities.forEach((city) => {
                if (parseInt(city.id) === parseInt(newPropertyLocation)) {
                    city.district.forEach((item) => {
                        vm.districts[item.name] = item.id;
                    });
                }
            });
        },
    },
    data() {
        return {
            filter: {},
            cities: {},
            citiesOptions: [],
            districts: {},
            propertyLocation: null,
            propertyDistrict: null,
            propertySettings: null,
        }
    },
    async mounted() {
        await this.$store.dispatch("property/getSettings");
        this.propertySettings = this.$store.state.property.settings ?? null;

        await this.$store.dispatch("location/getCities");
        this.cities = this.$store.state.location.cities ?? null;
        this.citiesOptions = this.$store.state.location.citiesOptions ?? null;
    },
    methods: {
        toggleAdvancedSearch() {
            this.$store.dispatch("utils/toggleAdvancedSearch", {});
        },
        searchProperty() {
            if (this.propertyLocation) {
                this.filter.city_id = this.propertyLocation;
            }
            this.$router.push({name: 'PropertySearchPage', query: this.filter})
            this.$store.dispatch("utils/toggleAdvancedSearch", {});
        },
    }
}