import RadioButton from '@/components/Common/Inputs/RadioButton/RadioButton';

import Dropdown from '@/components/Common/Inputs/Dropdown/Dropdown';
import InputField from '@/components/Common/Inputs/Input/Input';
import Button from '@/components/Common/Buttons/Button/Button';
import PlusMinusInput from '@/components/Common/Inputs/PlusMinusInput/PlusMinusInput';
import HeartIcon from '@/components/Common/Icons/HeartIcon/';
import LabelNew from '@/components/Common/Labels/LabelNew/LabelNew';
import Checkbox from '@/components/Common/Inputs/CheckBox/CheckBox';

export default {
    name: 'PropertySearchPagesNav',
    props: {
        resultsamount: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        phone: {
            type: String,
            default: '',
        },
        email: {
            type: String,
            default: '',
        },
        date: {
            type: String,
            default: '',
        },
        time: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        propertyid: {
            type: String,
            default: '',
        },
    },
    components: {

        Dropdown,
        InputField,
        Button,
        PlusMinusInput,
        HeartIcon,
        LabelNew,
        Checkbox,
    },
    mounted() {
    },
    data() {
        return {
            filterBoxActive: false,
            selectedOption: {title: this.$t('price-ascending')},
            translation: {
                sort_label: 'Подреди по',
            },
            sortingOptions: [
                {
                    title: this.$t('price-ascending'),
                    value: 'price_ascending',
                },
                {
                    title: this.$t('price-descending'),
                    value: 'price_descending',
                },
                {
                    title: this.$t('date-added'),
                    value: 'create_date',
                },
            ],
        };
    },
    methods: {
        toggleAdvancedSearchModal() {
            this.$store.dispatch('utils/toggleAdvancedSearch', {});
        },
        selectOption(selectedOption) {
            this.selectedOption = selectedOption;
            this.$emit('sort', selectedOption.value);
            this.filterBoxActive = false;
        },
    },
};
