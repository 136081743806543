<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{  'open-filter-box': filterBoxActive,
  }">
    <div class="search-results-nav-container d-none d-md-block ">
      <div class="d-flex align-items-center search-results-nav-desktop">
        <div class="me-auto col-5 result-number"><span>{{ resultsamount }}</span> {{ $t('results') }}</div>

        <div class="col-3 ">
          <!--
          <div class="sort-by me-1">{{ $t('sort-by') }}:</div>
          -->
          <div class="d-flex align-items-center sort-by-button ">
            <div class="ms-auto me-1" @click="filterBoxActive = !filterBoxActive"> {{ selectedOption.title }} </div>
            <svg class="me-2" width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.66675 1.16667L4.66675 11.8333" stroke="#FF7A00" stroke-linecap="round" />
              <path d="M0.666749 7.83334L4.66675 11.8333L8.66675 7.83334" stroke="#FF7A00" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
          <div class="sort-filter-modal ">
            <div class="sort-item" v-for="option in sortingOptions" @click="selectOption(option)"> {{ option.title }}
            </div>
          </div>
        </div>

        <Button @click="toggleAdvancedSearchModal()" class="col-3" :theme="'outline-orange'" :size="'super-small'"
          :text="$t('advanced-search')" :svg="'extendedsearch'" />
      </div>
    </div>
    <div class="search-results-nav d-md-none">
      <div class="d-flex align-items-center">
        <a href="#">
          <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3333 8.33301L1.66667 8.33301" stroke="#434D56" stroke-width="1.75" stroke-linecap="round" />
            <path d="M8.66667 1.33301L1.66667 8.33301L8.66667 15.333" stroke="#434D56" stroke-width="1.75"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </a>
        <div class="ms-3 result-number"><span>{{ resultsamount }}</span> {{ $t('results') }}</div>


       <div class="d-flex align-items-center sort-by-button ms-auto">
         <div class="me-1" @click="filterBoxActive = !filterBoxActive"> {{ selectedOption.title }} </div>
         <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M4.66675 1.16667L4.66675 11.8333" stroke="#FF7A00" stroke-linecap="round" />
           <path d="M0.666749 7.83334L4.66675 11.8333L8.66675 7.83334" stroke="#FF7A00" stroke-linecap="round"
             stroke-linejoin="round" />
         </svg>
       </div>


       <div class="sort-filter-modal ">
         <div class="sort-item" v-for="option in sortingOptions" @click="selectOption(option)"> {{ option.title }}</div>
       </div>


      </div>
      <div class="d-flex  extended-search-button justify-content-center" v-on:click="toggleAdvancedSearch">
        <div>
          <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_225_26995)">
              <path
                d="M3.94462 5.49782C4.16089 5.49782 4.36775 5.49782 4.57932 5.49782C6.19662 5.49782 7.80922 5.49782 9.42652 5.49782C9.83555 5.49782 10.0847 5.79411 9.96719 6.14685C9.89196 6.3726 9.73211 6.49017 9.49234 6.49017C9.0316 6.49017 8.57085 6.49017 8.11011 6.49017C6.789 6.49017 5.47259 6.49488 4.15148 6.48547C3.99634 6.48547 3.92581 6.5278 3.8694 6.683C3.53089 7.59541 2.6047 8.13156 1.67852 7.97165C0.728822 7.80705 0.0283045 6.99811 9.57234e-05 6.02927C-0.028113 5.10275 0.667703 4.22797 1.57038 4.04455C2.55299 3.84702 3.50268 4.35026 3.86469 5.26736C3.8882 5.34261 3.91641 5.41316 3.94462 5.49782ZM1.00621 6.00575C1.01091 6.55602 1.45285 6.99811 2.00292 6.99341C2.55299 6.99341 2.99022 6.54661 2.99022 5.99635C2.99022 5.44608 2.55299 4.99458 2.00292 4.98988C1.45755 4.98518 1.00151 5.44608 1.00621 6.00575Z"
                fill="#1A2128" />
              <path
                d="M6.05553 2.50238C4.23136 2.50238 2.426 2.50238 0.625344 2.50238C0.512509 2.50238 0.390271 2.48827 0.286839 2.44594C0.0846766 2.36129 -0.0187554 2.14024 0.0188562 1.9286C0.0611694 1.69344 0.22572 1.53824 0.460793 1.51943C0.54542 1.51473 0.634747 1.51473 0.719373 1.51473C2.4307 1.51473 4.13733 1.51473 5.84866 1.51943C6.00381 1.51943 6.07433 1.4818 6.13545 1.3266C6.46455 0.409493 7.38134 -0.126662 8.31693 0.0285407C9.26192 0.183743 9.96714 0.992679 9.99535 1.96622C10.0236 2.88803 9.33244 3.76752 8.42976 3.95094C7.44716 4.14847 6.49747 3.64524 6.13545 2.73283C6.10724 2.65758 6.08374 2.58704 6.05553 2.50238ZM8.98924 2.00385C8.98924 1.44888 8.552 1.00679 8.00193 1.00209C7.45186 0.997382 7.00522 1.43477 7.00052 1.98974C6.99582 2.55411 7.44716 3.01032 7.99723 3.00561C8.5473 3.00091 8.98924 2.55411 8.98924 2.00385Z"
                fill="#1A2128" />
            </g>
            <defs>
              <clipPath id="clip0_225_26995">
                <rect width="10" height="8" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="ms-2" @click="toggleAdvancedSearchModal()">{{ $t('advanced-search') }}</div>

      </div>
    </div>
  </div>
</template>

